import React, { useContext, useEffect, useRef, useState } from "react";
import close from "../../Assets/closeButton.png";
import styles from "./EditRegistration.module.css";
import uploadIcon from "../../Assets/uploadIcon.svg";
import { isEmpty } from "lodash";
// import UploadPopup from "../UploadPopup/UploadPopup";
import { ref, uploadBytesResumable } from "firebase/storage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { formValidate } from "../../utils/formAttributesValidate";
import ReactPlayer from "react-player";
import { useUserAuth } from "../../Context/UserAuthContext";
import { leadsStorage } from "../../store/firebase-config";
import Context from "../../Context/Context";

const EditRegistration = ({
  details,
  promotionData,
  setEdit,
  setMainPopup,
}) => {
  const [formValues, setFormValues] = useState({ ...details } || {});
  const { setUpdateData, setNotAdmin } = useContext(Context);
  const [changedValue, setChangedValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const imageUrlRef = useRef();
  const collegeIdRef = useRef();
  const aadharIdRef = useRef();
  const [source, setSource] = useState(details || {});
  const [progress, setProgress] = useState([]);
  const [file, setFile] = useState([]);
  const navigate = useNavigate();
  const [mediaSource, setMediaSource] = useState({});
  const mediaRef = useRef();
  const [otherMedias, setOtherMedias] = useState({});
  const { userIdToken, currentUser, verifyUser, userDetailsFromApi } =
    useUserAuth();
  const token = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleMediaSelect = async (event) => {
    const image = new FormData();
    let id = event.target.id;
    const imagedata = event.target.files[0];
    image.append("image", imagedata);
    let url = URL.createObjectURL(imagedata);
    setFile((prevState) => [...prevState, { id, fileData: imagedata }]);
    setSource((prevState) => ({ ...prevState, [id]: url }));
  };

  const handleChoose = async () => {
    imageUrlRef.current.click();
  };
  const handleChooseCollegeId = async () => {
    collegeIdRef.current.click();
  };
  const handleChooseIdProofImage = async () => {
    aadharIdRef.current.click();
  };

  const handleCollectUserInputs = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleWhatappStausChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      whatsAppNumber: !prevState?.whatsAppNumber,
    }));
    setChangedValues((prevState) => ({
      ...prevState,
      whatsAppNumber: !prevState?.whatsAppNumber,
    }));
  };
  const uploadGoviralMedias = async () => {
    setLoading(true);
    try {
      let medias = {};
      for (let i = 0; i < file.length; i++) {
        const fileData = file[i];
        let randomId = Math.floor(Date.now() * Math.random());
        const folderPath = `/${details?.promotionId}/${userDetailsFromApi?.userId}_${randomId}`;
        const storageRef = ref(leadsStorage, folderPath);

        const uploadTask = uploadBytesResumable(storageRef, fileData?.fileData);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const uploadprogress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[i] = uploadprogress;

              return newProgress;
            });

            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          }
        );
        // Wait for the upload task to complete before proceeding to the next file
        await uploadTask;

        let fileUrl = "https://storage.googleapis.com/leads_media" + folderPath;
        medias[fileData?.id] = fileUrl;
      }
      return medias;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const uploadOtherMedias = async () => {
    setLoading(true);
    try {
      const otherMediasArray = Object.entries(otherMedias).map(
        ([key, value]) => ({ key, value })
      );

      const goviralMediaFiles = Array.from(otherMediasArray);
      const medias = [...goviralMediaFiles];
      let otherMediasUploadResponse = {};

      for (let i = 0; i < medias.length; i++) {
        const file = medias[i]?.value;
        const key = medias[i]?.key;
        // add goviralMediaFiles path
        let randomId = Math.floor(Date.now() * Math.random());
        const folderPath = `/${details?.promotionId}/${userDetailsFromApi?.userId}_${randomId}`;
        const storageRef = ref(leadsStorage, folderPath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const uploadprogress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[i] = uploadprogress;
              return newProgress;
            });

            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          }
        );
        // Wait for the upload task to complete before proceeding to the next file
        await uploadTask;

        otherMediasUploadResponse[key] =
          "https://storage.googleapis.com/leads_media" + folderPath;
      }

      return otherMediasUploadResponse;
    } catch (error) {
      console.error(error);
      setLoading(false);
      return { error: true };
    }
  };
  const handleChooseMedia = async (e) => {
    mediaRef[e.currentTarget.id].click();
  };
  const handleFileChangeMedia = (event) => {
    const fieldName = event.currentTarget.id;
    const thumbnail = new FormData();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    thumbnail.append("thumbnail", file);
    setMediaSource((prev) => ({
      ...prev,
      [fieldName]: { url, type: file?.type },
    }));
    setOtherMedias((prev) => ({ ...prev, [fieldName]: file }));
  };
  const submitFormApi = async (uplodedMedia, otherMediasUploadResponse) => {
    setLoading(true);

    try {
      let dataToSubmit = {
        ...changedValue,
        ...uplodedMedia,
        ...otherMediasUploadResponse,
      };
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_EDIT_REGISTRATION_NEW}/${details?.orgId}/${details?.promotionId}/${details?.regId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSubmit,
      };
      const response = await axios(config);
      if (response?.data?.admin == false) {
        setLoading(false);
        setEdit(false);
        setProgress([]);
        return;
      }
      if (response?.data?.status == "success") {
        setUpdateData((prev) => prev + 1);
        setLoading(false);
        setEdit(false);
        setMainPopup(false);
        setProgress([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // let errors = {};
    // if (!isEmpty(promotionData?.formAttributes)) {
    //   const formvalidResponse = await formValidate(
    //     promotionData?.formAttributes,
    //     formValues,
    //     mediaSource,
    //     errors
    //   );
    // } else {
    //   errors = validate(formValues);
    // }

    // setFormErrors(errors);

    // if (isEmpty(errors)) {
    //   const otherMediasUploadResponse = await uploadOtherMedias();
    //   const responseFromUpload = await uploadGoviralMedias();
    //   submitFormApi(responseFromUpload, otherMediasUploadResponse);
    // }
    const otherMediasUploadResponse = await uploadOtherMedias();
    const responseFromUpload = await uploadGoviralMedias();
    submitFormApi(responseFromUpload, otherMediasUploadResponse);
  };
  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (emailAdress?.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (number?.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  const validate = (values) => {
    const errors = {};
    if (isEmpty(values.name)) {
      errors.name = "This field is required";
    }
    if (!source?.imageUrl) {
      errors.imageUrl = "Please upload your photo";
    }
    if (!source?.idProofImage) {
      errors.idProofImage = "Please upload Aadhaar Card";
    }
    if (!validatePhone(values.phone)) {
      errors.phone = "Please enter a valid phone number";
    }
    // if (!values?.regType) {
    //   errors.regType = "This field is required";
    // }
    // if (isEmpty(values.name)) {
    //   errors.name = "This field is required";
    // }
    // if (!source?.imageUrl) {
    //   errors.imageUrl = "Please upload your photo";
    // }
    // if (isEmpty(values.collegeIdNumber)) {
    //   errors.collegeIdNumber = "This field is required";
    // }
    // if (!source?.collegeIdImage) {
    //   errors.collegeIdImage = "Upload Collge Id Card";
    // }
    // if (!values?.collegeName && values?.regType !== "insider") {
    //   errors.collegeName = "Please enter collge Name";
    // }
    // if (!values?.idProofNumber && values?.regType !== "insider") {
    //   errors.idProofNumber = "Please enter id number";
    // }
    // if (!source?.idProofImage && values?.regType !== "insider") {
    //   errors.idProofImage = "Please upload Aadhaar Card";
    // }
    // if (!values?.wardName && values?.regType == "general") {
    //   errors.wardName = "Please enter your ward name";
    // }
    // if (!values?.relation && values?.regType == "general") {
    //   errors.relation = "Please enter your relationship with the ward";
    // }
    // if (!validateEmail(values.userEmail)) {
    //   errors.userEmail = "Please enter a valid Email";
    // }
    // if (!validatePhone(values.phone)) {
    //   errors.phone = "Please enter a valid phone number";
    // }

    return errors;
  };

  return (
    <>
      {/* {!isEmpty(progress) && (
        <UploadPopup
          progress={progress}
          message="Submitting Details - Admin will review soon"
        />
      )} */}
      <div className="w-[100%] h-[100%] fixed top-0 left-0 flex flex-col items-center justify-center serviceformbg backdrop-blur-xl z-[50] overflow-x-hidden overflow-y-auto no-scrollbar">
        <div className=" absolute top-0 px-[3%] w-full">
          <div className="h-auto  box w-full w-full max-w-[800px]  bg-[#FFFFFF] rounded-[20px] my-10 mx-auto">
            <div className="w-[100%] h-[30%] relative rounded-t-[20px] overflow-hidden w-full px-[16px]">
              <div
                className=" rounded-full bg-white h-[48px] w-[48px] flex justify-center items-center hover:cursor-pointer ml-auto"
                onClick={() => setEdit(false)}
              >
                <img src={close} alt="" className="h-[16px] w-[16px] " />
              </div>
              {!isEmpty(promotionData?.formAttributes) ? (
                promotionData?.formAttributes?.map((el) => {
                  if (el.attrType == "scoring") {
                    return (
                      <div className="flex flex-col gap-[10px] mt-[30px]">
                        <label className="text-regular-medium">
                          {el.title}
                          {el.required && (
                            <span className="text-[#FA6B6B]">*</span>
                          )}
                        </label>
                        {el.items.map((key) => {
                          return (
                            <>
                              {/* <h1 className="text-[#B5B5B5]">{key}</h1> */}
                              {el?.fieldType == "textfield" ? (
                                <textarea
                                  type={el.type}
                                  min="0"
                                  value={formValues.property}
                                  placeholder={el?.placeHolder}
                                  onChange={(e) => {
                                    setFormValues({
                                      ...formValues,
                                      [el.property[key]]: e.target.value,
                                    });
                                    setChangedValues((prevState) => ({
                                      ...prevState,
                                      [el.property[key]]: e.target.value,
                                    }));
                                  }}
                                  className={
                                    formErrors[el.property[key]]
                                      ? styles.error
                                      : ""
                                  }
                                />
                              ) : (
                                <input
                                  type={el.type}
                                  min="0"
                                  value={formValues[el.property[key]]}
                                  placeholder={el?.placeHolder}
                                  onChange={(e) => {
                                    setFormValues({
                                      ...formValues,
                                      [el.property[key]]: e.target.value,
                                    });
                                    setChangedValues((prevState) => ({
                                      ...prevState,
                                      [el.property[key]]: e.target.value,
                                    }));
                                  }}
                                  className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                                    formErrors[el.property[key]]
                                      ? styles.errorOutline
                                      : ""
                                  } ${styles.placeholder}`}
                                />
                              )}
                              {formErrors[el.property[key]] && (
                                <p className="text-[#FA6B6B] text-smallest ">
                                  {formErrors[el.property[key]]}
                                </p>
                              )}
                            </>
                          );
                        })}
                      </div>
                    );
                  } else if (el.attrType == "prediction") {
                    return (
                      <div className="flex flex-col gap-[10px] mt-[20px]">
                        <label className="text-regular-medium">
                          {el.title}
                          {el.required && (
                            <span className="text-[#FA6B6B]">*</span>
                          )}
                        </label>
                        <select
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [el.property]: e.target.value,
                            });
                            setChangedValues((prevState) => ({
                              ...prevState,
                              [el.property]: e.target.value,
                            }));
                          }}
                          className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                            styles.placeholder
                          } 
                        ${formErrors[el.property] ? styles.errorOutline : ""}
                        `}
                        >
                          <option selected disabled>
                            select an option
                          </option>
                          {el.items.map((key) => {
                            return (
                              <>
                                <option className="">{key}</option>
                              </>
                            );
                          })}
                        </select>
                        {formErrors[el.property] && (
                          <p className="text-[#FA6B6B] text-smallest ">
                            {formErrors[el.property]}
                          </p>
                        )}
                      </div>
                    );
                  } else if (el.attrType == "media") {
                    return (
                      <div className="flex flex-col gap-[10px] mt-[30px]">
                        <label className="text-regular-medium">
                          {el.title}
                          {el.required && (
                            <span className="text-[#FA6B6B]">*</span>
                          )}
                        </label>
                        {el?.items?.map((key) => {
                          return (
                            <div>
                              <div className="w-[100%]">
                                <span className="text-[#B5B5B5]  text-smallest">
                                  {el?.placeHolder}
                                </span>
                              </div>
                              <input
                                ref={(element) => {
                                  mediaRef[el?.property[key]] = element;
                                }}
                                className="hidden"
                                type="file"
                                onChange={handleFileChangeMedia}
                                accept={el?.fieldType}
                                id={el?.property[key]}
                              />
                              <div
                                className={`w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                                 ${
                                   formErrors[el?.property[key]]
                                     ? "border-[#fa6b6b]"
                                     : ""
                                 }`}
                              >
                                {mediaSource[el?.property[key]] ? (
                                  <div
                                    className="min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]"
                                    onClick={handleChooseMedia}
                                    id={el?.property[key]}
                                  >
                                    <div
                                      className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center`}
                                    >
                                      {mediaSource[
                                        el?.property[key]
                                      ]?.type?.startsWith("image") && (
                                        <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                                          <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                            <img
                                              src={
                                                mediaSource[el?.property[key]]
                                                  .url
                                              }
                                              className="outline-none w-auto h-[100%] object-cover"
                                            />
                                          </div>
                                          <p>Change Photo</p>
                                        </div>
                                      )}
                                      {mediaSource[
                                        el?.property[key]
                                      ]?.type?.startsWith("video") && (
                                        <div className="h-[320px] h-auto w-[100%] overflow-hidden flex items-center justify-center mb-[15px] bg-[#1c1c1c]">
                                          <ReactPlayer
                                            playing={false}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            url={
                                              mediaSource[el?.property[key]].url
                                            }
                                            style={{ objectFit: "contain" }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="min-h-[60px] w-full rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]"
                                    id={el?.property[key]}
                                    onClick={handleChooseMedia}
                                  >
                                    <div>
                                      <img
                                        src={uploadIcon}
                                        className="h-[80px] mx-auto"
                                      />
                                      <p>Select Photo</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {formErrors[el?.property[key]] && (
                                <p className="text-[#FA6B6B] text-smallest mt-[5px]">
                                  {formErrors[el?.property[key]]}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  {/* <div className="mt-[20px] px-[20px]">
                <p className="text-regular-medium">
                  Register as
                  <span className="text-[#FA6B6B]">*</span>
                </p>
                <div className=" gap-[10px] py-[10px]">
                  <div className="flex items-center ">
                    <input
                      type="radio"
                      id="insider"
                      name="regType"
                      onChange={() => {
                        setFormValues((prev) => ({
                          ...prev,
                          regType: "insider",
                        }));
                        setChangedValues((prev) => ({
                          ...prev,
                          regType: "insider",
                        }));
                      }}
                      className="w-[20px] h-[20px] py-[10px]"
                      checked={formValues?.regType == "insider"}
                    />
                    <label htmlFor="insider" className="pl-[10px] py-[10px]">
                      College student
                    </label>
                  </div>
                  <div className="flex items-center ">
                    <input
                      type="radio"
                      id="outsider"
                      name="regType"
                      onChange={() => {
                        setFormValues((prev) => ({
                          ...prev,
                          regType: "outsider",
                        }));
                        setChangedValues((prev) => ({
                          ...prev,
                          regType: "outsider",
                        }));
                      }}
                      className="w-[20px] h-[20px]"
                      checked={formValues?.regType == "outsider"}
                    />
                    <label htmlFor="outsider" className="pl-[10px] py-[10px]">
                      Outside College student
                    </label>
                  </div>
                  <div className="flex items-center ">
                    <input
                      type="radio"
                      id="general"
                      name="regType"
                      onChange={() => {
                        setFormValues((prev) => ({
                          ...prev,
                          regType: "general",
                        }));
                        setChangedValues((prev) => ({
                          ...prev,
                          regType: "general",
                        }));
                      }}
                      className="w-[20px] h-[20px]"
                      checked={formValues?.regType == "general"}
                    />
                    <label htmlFor="general" className="pl-[10px] py-[10px]">
                      Guardian/Parent Registration
                    </label>
                  </div>
                </div>
                {formErrors?.regType && (
                  <span className={styles.error}>
                    <span className="text-red-600">*</span>
                    {formErrors.regType}
                  </span>
                )}
              </div> */}
                  <div className="px-[20px] md:px-[25px] text-black">
                    <>
                      {/* NAME */}
                      <div className="mt-[25px]">
                        <p className="text-regular-medium">
                          Name <span className="text-[#FA6B6B]">*</span>
                        </p>
                        <input
                          className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                            formErrors.name ? styles.errorOutline : ""
                          }`}
                          type="text"
                          id="name"
                          value={formValues?.name}
                          onChange={handleCollectUserInputs}
                        />
                        {formErrors.name && (
                          <span className={styles.error}>
                            <span className="text-red-600">*</span>
                            {formErrors.name}
                          </span>
                        )}
                      </div>
                      {/* PHOTO */}
                      <div className="flex flex-col gap-[10px] my-[20px]">
                        <label
                          htmlFor="imageUrl"
                          className="text-regular-medium"
                        >
                          <input
                            ref={imageUrlRef}
                            className="hidden"
                            type="file"
                            onChange={handleMediaSelect}
                            accept="image/*"
                            id="imageUrl"
                          />
                          Upload Your Photo{" "}
                          <span className="text-red-600">*</span>
                        </label>

                        <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                          <div
                            className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                         ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                           `}
                            onClick={handleChoose}
                          >
                            {source?.imageUrl ? (
                              <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                                <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                  <img
                                    src={source?.imageUrl}
                                    className="outline-none w-auto h-[100%] object-cover"
                                  />
                                </div>
                                <p>Change Photo</p>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={uploadIcon}
                                  className="h-[80px] mx-auto"
                                />
                                <p>Select Photo</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {formErrors.imageUrl && (
                          <span className={styles.error}>
                            <span className="text-red-600">*</span>
                            {formErrors.imageUrl}
                          </span>
                        )}
                      </div>
                      {/* AAdhar ID UPLOAD */}
                      <div className="flex flex-col gap-[10px] my-[20px]">
                        <label
                          htmlFor="imageUrl"
                          className="text-regular-medium"
                        >
                          <input
                            ref={aadharIdRef}
                            className="hidden"
                            type="file"
                            onChange={handleMediaSelect}
                            accept="image/*"
                            id="idProofImage"
                          />
                          Upload Aadhaar Card{" "}
                          <span className="text-red-600">*</span>
                        </label>

                        <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                          <div
                            className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                        `}
                            onClick={handleChooseIdProofImage}
                          >
                            {source?.idProofImage ? (
                              <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                                <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                  <img
                                    src={source?.idProofImage}
                                    className="outline-none w-auto h-[100%] object-cover"
                                  />
                                </div>
                                <p>Change Photo</p>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={uploadIcon}
                                  className="h-[80px] mx-auto"
                                />
                                <p>Select Photo</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {formErrors.idProofImage && (
                          <span className={styles.error}>
                            <span className="text-red-600">*</span>
                            {formErrors.idProofImage}
                          </span>
                        )}
                      </div>
                    </>
                    {/* {formValues?.regType == "outsider" && (
                  <>
                    NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        value={formValues?.name}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    PHOTO
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                         ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                           `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    COLLEGE NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        College Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeName"
                        value={formValues?.collegeName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeName}
                        </span>
                      )}
                    </div>
                    COLLEGE ID
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    COLLEGE ID UPLOAD
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload College Id{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>

                    ID PROOF NUMBER
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Aadhaar Card Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.idProofNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="idProofNumber"
                        value={formValues?.idProofNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.idProofNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofNumber}
                        </span>
                      )}
                    </div>
                    AAdhar ID UPLOAD
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={aadharIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="idProofImage"
                        />
                        Upload Aadhaar Card{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                        `}
                          onClick={handleChooseIdProofImage}
                        >
                          {source?.idProofImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.idProofImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.idProofImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofImage}
                        </span>
                      )}
                    </div>
                  </>
                )} */}

                    {/* {formValues?.regType == "insider" && (
                  <>
                    NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        onChange={handleCollectUserInputs}
                        value={formValues?.name}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    PHOTO
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                    ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                    `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    COLLEGE ID
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    COLLEGE ID UPLOAD
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload College Id{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>
                  </>
                )} */}

                    {/* {formValues?.regType == "general" && (
                  <>
                    NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Guardian Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.name ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="name"
                        value={formValues?.name}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.name && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.name}
                        </span>
                      )}
                    </div>
                    OCCUPATION
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">Guardian Occupation</p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.occupation ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="occupation"
                        value={formValues?.occupation}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.occupation && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.occupation}
                        </span>
                      )}
                    </div>
                    PHOTO
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={imageUrlRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="imageUrl"
                        />
                        Upload Your Photo{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.imageUrl ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChoose}
                        >
                          {source?.imageUrl ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.imageUrl}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.imageUrl && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.imageUrl}
                        </span>
                      )}
                    </div>
                    ID PROOF NUMBER
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Aadhaar Card Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.idProofNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="idProofNumber"
                        value={formValues?.idProofNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.idProofNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofNumber}
                        </span>
                      )}
                    </div>
                    AAdhar ID UPLOAD
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={aadharIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="idProofImage"
                        />
                        Upload Aadhaar Card{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.idProofImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseIdProofImage}
                        >
                          {source?.idProofImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.idProofImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.idProofImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.idProofImage}
                        </span>
                      )}
                    </div>

                    WARD NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Ward Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.wardName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="wardName"
                        value={formValues?.wardName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.wardName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.wardName}
                        </span>
                      )}
                    </div>
                    Relation eith ward
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Relationship with Ward
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.relation ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="relation"
                        value={formValues?.relation}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.relation && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.relation}
                        </span>
                      )}
                    </div>
                    COLLEGE NAME
                    <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        College Name <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeName ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeName"
                        value={formValues?.collegeName}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeName && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeName}
                        </span>
                      )}
                    </div>
                    COLLEGE ID
                    <div className="mt-[20px]">
                      <p className="text-regular-medium">
                        Ward College ID Number{" "}
                        <span className="text-[#FA6B6B]">*</span>
                      </p>
                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none ${
                          formErrors.collegeIdNumber ? styles.errorOutline : ""
                        }`}
                        type="text"
                        id="collegeIdNumber"
                        value={formValues?.collegeIdNumber}
                        onChange={handleCollectUserInputs}
                      />
                      {formErrors.collegeIdNumber && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdNumber}
                        </span>
                      )}
                    </div>
                    COLLEGE ID UPLOAD
                    <div className="flex flex-col gap-[10px] my-[20px]">
                      <label htmlFor="imageUrl" className="text-regular-medium">
                        <input
                          ref={collegeIdRef}
                          className="hidden"
                          type="file"
                          onChange={handleMediaSelect}
                          accept="image/*"
                          id="collegeIdImage"
                        />
                        Upload Ward College ID{" "}
                        <span className="text-red-600">*</span>
                      </label>

                      <div className=" min-h-[60px] rounded-[5px]  overflow-hidden text-center cursor-pointer text-black focus:outline-0 font-Inter font-[400] text-[17px] leading-[24px]">
                        <div
                          className={`py-[20px] w-[100%] relative h-[170px] flex items-center justify-center border-2 border-dashed 
                     ${formErrors.collegeIdImage ? "border-[#fa6b6b]" : ""}
                   `}
                          onClick={handleChooseCollegeId}
                        >
                          {source?.collegeIdImage ? (
                            <div className="h-[100%] w-auto overflow-hidden flex flex-col justify-center">
                              <div className="h-[100%] w-auto overflow-hidden mx-auto">
                                <img
                                  src={source?.collegeIdImage}
                                  className="outline-none w-auto h-[100%] object-cover"
                                />
                              </div>
                              <p>Change Photo</p>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={uploadIcon}
                                className="h-[80px] mx-auto"
                              />
                              <p>Select Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {formErrors.collegeIdImage && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.collegeIdImage}
                        </span>
                      )}
                    </div>
                  </>
                )} */}

                    {formValues?.regType && (
                      <>
                        {/* EMAIL  */}
                        {/* <div className="mt-[25px]">
                      <p className="text-regular-medium">
                        Email <span className="text-[#FA6B6B]">*</span>
                      </p>

                      <input
                        className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px] ${
                          formErrors.userEmail ? styles.errorOutline : ""
                        } ${styles.placeholder}`}
                        type="email"
                        id="userEmail"
                        placeholder="Enter email to receive additional information"
                        onChange={handleCollectUserInputs}
                        value={formValues?.userEmail}
                      />
                      {formErrors.userEmail && (
                        <span className={styles.error}>
                          <span className="text-red-600">*</span>
                          {formErrors.userEmail}
                        </span>
                      )}
                    </div> */}
                        {/* PHONE NUMBER  */}
                        <div className="mt-[25px]">
                          <p className="text-regular-medium">
                            Phone Number{" "}
                            <span className="text-[#FA6B6B]">*</span>
                          </p>
                          <input
                            className={`w-[100%] border-2 rounded-md px-[16px] py-[15px] mt-[10px] outline-none text-[14px]
                        ${styles.placeholder} ${
                              formErrors.phone ? styles.errorOutline : ""
                            }`}
                            type="text"
                            placeholder="Share your number"
                            id="phone"
                            value={formValues?.phone}
                            onChange={handleCollectUserInputs}
                          />
                          {formErrors.phone && (
                            <span className={styles.error}>
                              <span className="text-red-600">*</span>
                              {formErrors.phone}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-[10px] mt-[10px] cursor-pointer">
                          <input
                            type="checkbox"
                            name="whatsAppNumber"
                            id="whatsAppNumber"
                            onClick={handleWhatappStausChange}
                            checked={formValues?.whatsAppNumber}
                            className={styles.whatsapp}
                          />
                          <label
                            htmlFor="whatsAppNumber"
                            className="text-small text-left cursor-pointer"
                          >
                            WhatsApp number
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              <div className="flex justify-center">
                <button
                  className="p-[10px] bg-black text-white my-[20px] w-[50%] mx-auto rounded-[5px] disabled:opacity-30"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submiting.." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRegistration;
