// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCiC_SeM7LX8hFi_jruJPYNMUtgg8P6Y-g",
  authDomain: "tripsmagic.firebaseapp.com",
  projectId: "tripsmagic",
  storageBucket: "tripsmagic.appspot.com",
  messagingSenderId: "1077050186521",
  appId: "1:1077050186521:web:d42e50de7b5a42fb080388",
  measurementId: "G-37VHZHVS46",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
export const storage = getStorage(app);
export const adsStorage = getStorage(app, "goviralads");
export const organizerStorage = getStorage(app, "organizer_medias");
export const influencerStorage = getStorage(app, "goviralinfluencers");
export const posterStorage = getStorage(app, "goviral_posters");
export const promotionStorage = getStorage(app, "goviral_promotions");
export const leadsStorage = getStorage(app, "leads_media");
